import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../../../utils/API/apiHandler';

const initialState = {
	searchQuery: '',
	data: null,
	loading: false,
	error: false,
	offices: null,
	officeInEMR: null,
	needsFileLocationToRunEMR: null,
};

export const getAllInitData = createAsyncThunk('emr/getAllInitData', async (payload, { getState }) => {
	const { id } = payload || {};
	const offices = await request({ url: `/tickets/ats/${id}/getOfficesEMR`, fallbackData: [] });
	const needsFileLocationToRunEMR = await request({ url: `/tickets/ats/${id}/needsFileLocationToRunEMR` });

	return {
		offices,
		needsFileLocationToRunEMR,
	};
});

export const checkWaitingList = createAsyncThunk('emr/checkWaitingList', async (payload, { getState }) => {
	const { id } = payload || {};
	return request({ url: `/tickets/ats/${id}/stages/emr/checkWaitingList`, zeroState: [] });
});

const emr = createSlice({
	name: 'emrReducer',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllInitData.pending]: (state, { payload }) => {
			state.loading = true;
		},
		[getAllInitData.rejected]: (state, { payload }) => {
			state.loading = false;
		},
		[getAllInitData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.offices = payload.offices;
			state.officeInEMR = payload.officeInEMR;
			state.needsFileLocationToRunEMR = payload.needsFileLocationToRunEMR;
		},
	},
});

export const { reducer, actions } = emr;
