import React from 'react';

export default function PlusIcon({ size = 16, color = 'currentColor', ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" {...props} width={size} height={size} viewBox="0 0 20 20">
			<g fill={color}>
				<path d="M5 11a1 1 0 1 1 0-2h10a1 1 0 1 1 0 2z"></path>
				<path d="M9 5a1 1 0 0 1 2 0v10a1 1 0 1 1-2 0z"></path>
			</g>
		</svg>
	);
}
