import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { flattenObject } from '../Department/TicketsList/utils';
import notification from '../../shared/components/ui/Notification/Notification';
import request from '../../utils/API/apiHandler';
import { STAGES } from './stageTypesMap';

const initialState = {
	ticket: {},
	missingDependencies: {},
	loading: false,
	error: false,
	sideBarSections: [],
	nextTicket: '',
	prevTicket: '',
	showModal: true,
	messages: [],
	certificateData: null,
	interviewLocations: null,
	currentPageData: [],
	initialTabDataPatientCaregiver: {},
	currentTabDataPatientCaregiver: {},
	metaInfo: null,
	languagesHHAU: null,
	company: null,
	showMarkTrainingCompletedButton: false,
	loadingUploadFiles: { total: 0, processed: 0, percentage: 0 },
};

export const updateEntity = createAsyncThunk('updateEntity/patch', ({ id, data, entityType }) => {
	const errorCallback = (err, errMessage) => {
		if (errMessage?.slice(0, 35) === 'Field "primaryPhone" must be unique') {
			notification('error', 'Error', 'Phone already in use');
		} else if (errMessage === 'Request failed with status code 406') {
			notification('error', 'Error', 'Request failed');
		}
	};

	return request({
		method: 'patch',
		url: `/entities/${entityType}/${id}`,
		data: {
			operation: 'replace',
			data,
		},
		errorCallback,
	}).then(resp => {
		return resp;
	});
});

export const confirmCaregiver = createAsyncThunk('updateTicket/patch', ({ id, caregiver }) => {
	return request({
		url: `/tickets/coordination/${id}/confirm`,
		method: 'patch',
		data: {
			id,
			operation: 'replace',
			caregiver,
		},
	}).then(resp => {
		return resp;
	});
});

export const deleteTicket = createAsyncThunk('deleteTicket/patch', ({ id, params, data, operation }, { getState }) => {
	const { departmentName } = params;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	return request({
		method: 'delete',
		url: `/${param}/${departmentName}/${id}`,
		data,
	}).then(resp => resp);
});

export const getTicket = createAsyncThunk('tickets/get', async ({ id, params, showModal }, { getState, dispatch }) => {
	const { departmentName } = params;

	const header = getState().departmentHeader?.selectedTab;
	const activeTabName = getState().navigationReducer?.activeTabOriginalName;
	const headersQuery = `?_headers=${header}`;

	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	try {
		const resp = await request({ url: `/${param}/${departmentName}/${id}${headersQuery}`, notify: true });

		if (departmentName === 'ats') {
			const payload = {
				ticketId: id,
				stage: activeTabName,
				resetState: true,
			};
			dispatch(getTicketMissingDeps({ payload }));
		}

		return Promise.resolve({ showModal, resp });
	} catch (err) {
		return Promise.reject(err);
	}
});

export const getTicketMissingDeps = createAsyncThunk('missing-deps/get', async ({ payload }, { getState, dispatch }) => {
	try {
		const stage = STAGES[payload.stage];
		const { applicants, stages, paths } = await request({ url: `/tickets/ats/checkStageDependencies/${payload.ticketId}/${stage}` });
		const response = {
			[stage]: {
				hasMissingDependencies: applicants?.length > 0 || stages?.length > 0 || paths?.length > 0,
				applicants,
				stages,
				paths,
			},
			resetState: payload.resetState,
		};
		return response;
	} catch (err) {
		console.error(err);
		throw err;
	}
});

export const getCertificateData = createAsyncThunk('certificate/get', async (id, { dispatch }) => {
	try {
		return await request({ url: `/tickets/hhau/certificate/${id}` });
	} catch (err) {
		dispatch(push('/500'));
		return Promise.reject(err);
	}
});

export const getCompanyName = createAsyncThunk('companyName/get', async (id, { dispatch }) => {
	try {
		return await request({ url: `/tickets/ats/getCompanyName` });
	} catch (err) {
		return Promise.reject(err);
	}
});

export const getLanguagesHHAU = createAsyncThunk('languagesHHAU/get', async (id, { dispatch }) => {
	return await request({ url: `/tickets/hhau/available-languages` });
});

export const getInterviewLocations = createAsyncThunk('interviewLocations/get', async ({ params }) => {
	const { departmentName } = params;
	try {
		return await request({ url: `/tickets/${departmentName}/interview-locations` });
	} catch (err) {
		return Promise.reject(err);
	}
});

export const getTicketCoord = createAsyncThunk('ticketsCoord/get', ({ params, data, showModal }, { getState, dispatch }) => {
	const { departmentName } = params;
	const dataFormated = queryString.stringify(flattenObject({ _id: data._id, matched: data.matched, _headers: data._headers }), {
		arrayFormat: 'bracket',
	});
	return request({ url: `/tickets/${departmentName}/?${dataFormated}` }).then(resp => {
		return { resp: resp, showModal };
	});
});

export const updateTicket = createAsyncThunk('updateTicket/patch', ({ id, params, operation, data }) => {
	const { departmentName } = params;
	return request({
		method: 'patch',
		url: `/tickets/${departmentName}/${id}`,
		data: {
			operation,
			data: { ...data },
		},
	}).then(resp => {
		return resp;
	});
});

export const updateDuplication = createAsyncThunk('updateDuplication/post', async ({ id, departmentName, data }, { rejectWithValue }) => {
	try {
		const response = await request({
			method: 'post',
			url: `/tickets/${departmentName}/update-duplication/${id}`,
			data: data,
		});
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const updateMatchInfo = createAsyncThunk('/updateMatchInfo/post', async ({ id, departmentName, data }, { rejectWithValue }) => {
	try {
		const response = await request({
			method: 'post',
			url: `/tickets/${departmentName}/handle-potential-match-chrc/${id}`,
			data: data,
		});
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const updateEmrInfo = createAsyncThunk('/updateEmrInfo/post', async ({ id, departmentName, data }, { rejectWithValue }) => {
	try {
		const response = await request({
			method: 'post',
			url: `/tickets/${departmentName}/handle-emr-info-update/${id}`,
			data: data,
		});
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const getMetaInfo = createAsyncThunk('getMetaInfo/get', async ({ params }) => {
	try {
		const { departmentName = 'ats' } = params || {};
		return await request({ url: `/tickets/${departmentName}/meta-info`, zeroState: {} });
	} catch (err) {
		return Promise.reject(err);
	}
});

const ticketDetails = createSlice({
	name: 'ticketDetails',
	initialState,
	reducers: {
		setShowTrainingCompletedButton: (state, { payload }) => {
			state.showMarkTrainingCompletedButton = payload;
		},
		setMissingDependencies: (state, { payload }) => {
			state.missingDependencies = payload;
		},
		setCurrentPageData: (state, { payload }) => {
			state.currentPageData = payload;
		},
		resetModal: (state, { payload }) => {
			state.showModal = payload !== 'hide';
		},
		setTicket: (state, { payload }) => {
			state.ticket = payload.ticket;
		},
		resetTicket: state => {
			state.ticket = { ...state.ticket };
		},
		initLoadingUploadFiles: (state, { payload }) => {
			state.loadingUploadFiles = { percentage: 0, total: payload, processed: 0 };
		},
		countLoadingUploadFiles: state => {
			let { percentage, processed, total } = state.loadingUploadFiles;
			processed += 1;
			percentage = Math.ceil((processed * 100) / total);
			state.loadingUploadFiles = { percentage, processed, total };
		},
	},
	extraReducers: {
		[getCertificateData.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getCertificateData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.certificateData = payload;
		},
		[getCertificateData.pending]: state => {
			state.loading = true;
		},
		[getInterviewLocations.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getInterviewLocations.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.interviewLocations = payload;
		},
		[getInterviewLocations.pending]: state => {
			state.loading = true;
		},
		[getTicketMissingDeps.fulfilled]: (state, { payload }) => {
			const { resetState, ...restPayload } = payload;

			if (resetState) {
				state.missingDependencies = restPayload;
			} else {
				state.missingDependencies = {
					...state.missingDependencies,
					...restPayload,
				};
			}
		},
		[getTicket.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getTicket.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.ticket = payload.resp && (payload.resp.ticket ? payload.resp.ticket : payload.resp.entity);
			state.nextTicket = payload.resp && payload.resp.next;
			state.prevTicket = payload.resp && payload.resp.previous;
			state.showModal = !(payload.showModal && payload.showModal === 'hide');
		},
		[getTicket.pending]: state => {
			state.loading = true;
		},
		[updateTicket.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[updateTicket.fulfilled]: (state, { payload }) => {
			state.loading = false;
		},
		[updateTicket.pending]: state => {
			state.loading = true;
		},
		[getTicketCoord.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getTicketCoord.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.coordTicket = payload.resp;
		},
		[getTicketCoord.pending]: state => {
			state.loading = true;
		},
		[getMetaInfo.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getMetaInfo.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.metaInfo = payload;
		},
		[getMetaInfo.pending]: state => {
			state.loading = true;
		},
		[getLanguagesHHAU.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getLanguagesHHAU.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.languagesHHAU = payload;
		},
		[getLanguagesHHAU.pending]: state => {
			state.loading = true;
		},
		[getCompanyName.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getCompanyName.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.company = payload;
		},
		[getCompanyName.pending]: state => {
			state.loading = true;
		},
		[updateDuplication.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[updateDuplication.rejected]: (state, action) => {
			state.loading = false;
		},
		[updateMatchInfo.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[updateMatchInfo.rejected]: (state, action) => {
			state.loading = false;
		},
		[updateEmrInfo.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[updateEmrInfo.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});
export const { reducer, actions, state } = ticketDetails;
